import React from 'react';
import PropTypes from 'prop-types';
import { FelaComponent } from 'react-fela';

import style from './Heading1.style';

const Heading1 = ({ color, light, element, ...props }) => (
    <FelaComponent color={color} style={style} light={light}>
        {({ className }) =>
            React.createElement(
                element,
                { ...props, className: `Heading1 ${props.className} ${className}` },
                props.children,
            )
        }
    </FelaComponent>
);

Heading1.propSizes = {
    className: PropTypes.string,
    color: PropTypes.string,
    element: PropTypes.string,
    light: PropTypes.bool,
};

Heading1.defaultProps = {
    className: '',
    color: 'black',
    element: 'h1',
    light: false,
};

export default Heading1;
