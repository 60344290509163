module.exports = [
    {
        locale: 'cs',
        label: 'Čeština',
        routes: {
            index: '/',
            festival: '/festival',
            features: '/funkce',
            conference: '/konference',
            notFound: '/404.html',
            privacyPolicy: '/zasady-zpracovani-osobnich-udaju',
            removeAccount: '/vymazani-uctu',
        },
    },
    {
        default: true,
        locale: 'en',
        label: 'English',
        routes: {
            index: '/',
            festival: '/festival',
            features: '/features',
            conference: '/conference',
            notFound: '/404.html',
            privacyPolicy: '/privacy-policy.html',
            removeAccount: '/remove-account',
        },
    },
];
