export default {
    fontFamily: 'Greycliff, sans-serif',
    fontFamilyRoboto: 'Roboto Condensed, sans-serif',
    colors: {
        black: '#000000',
        lightBlack: 'rgba(0,0,0,0.7)',
        navBlack: 'rgba(0,0,0,0.8)',
        transparent: 'transparent',
        white: '#ffffff',

        greyLight: '#eef1f3',
        greyLighter: '#A6B7C8',
        grey: '#677c8a',
        greyDark: '#303843',
        greyDarkLighter: '#2e343d',
        greyDarker: '#44484b',
        greyDarkest: '#20252D',
        greyAddress: '#BCBEC0',
        greyFooter: 'rgba(216, 216, 216, 0.1)',

        green: '#4ab48b',
        greenHover: '#3b9170',
        greenHoverLight: 'rgba(74, 180, 139, 0.10)',
        greenLight: '#5cdfb1',
        greenDark: '#1D9466',

        red: '#e4434a',
        lightRed: '#F9EAEA',
        darkRed: '#C3373D',

        gold: '#F99D3C',

        yellowWhite: '#fbf5e0',
    },
};
