const getDefaultLanguage = require('./getDefaultLanguage');
const findLanguage = require('./findLanguage');

const getLocalizedPath = (originalPath, locale) => {
    const lang = findLanguage(locale);

    // Returns undefined if route does not exists for specific path
    if (!lang) {
        return undefined;
    }

    let keyPath = originalPath.replace(/\//g, '');

    // Detect 404
    if (keyPath.match(/^\/?404\/?/gm)) keyPath = 'notFound';

    /* Supports hash in path */
    let hash = keyPath.match(/#(.*)/);
    if (hash) {
        hash = hash[0]; // full match
        keyPath = keyPath.replace(hash, '');
    } else {
        hash = '';
    }

    if (keyPath === '') keyPath = 'index';

    const defaultLanguage = getDefaultLanguage();
    return locale === defaultLanguage.locale
        ? `${defaultLanguage.routes[keyPath]}${hash}`
        : `/${locale}${lang.routes[keyPath]}${hash}`;
};

module.exports = getLocalizedPath;
