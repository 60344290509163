import buttonStyle from '../Button/Button.style';

export const LinkType = {
    GATSBY: 'gatsby',
    NATIVE: 'native',
};

export const LinkStyle = {
    LINK: 'link',
    BUTTON: 'button',
};

const activeStyle = {
    borderBottom: 'none',
};

export default ({ theme, styleType, position }) => {
    if (styleType === LinkStyle.BUTTON) {
        return {
            ...buttonStyle({ theme }),
            ...(position === 'left' && {
                float: 'left',
            }),
            ...(position === 'right' && {
                float: 'right',
            }),
        };
    }
    return {
        paddingBottom: 0,

        fontSize: 'inherit',
        fontWeight: 'bold',
        lineHeight: 'inherit',

        ...(position === 'left' && {
            float: 'left',
        }),
        ...(position === 'right' && {
            float: 'right',
        }),

        color: theme.colors.red,

        borderBottom: `1px solid ${theme.colors.red}`,
        textDecoration: 'none',
        ':hover': activeStyle,
        ':focus': activeStyle,
        ':active': activeStyle,
    };
};
