const disabledActionStyle = {
    opacity: 1,
};

export default ({ theme }) => ({
    display: 'inline-block',
    padding: '0.75rem 1.75rem',

    fontSize: '1.125rem',
    fontWeight: 'bold',

    color: theme.colors.white,
    backgroundColor: theme.colors.green,

    border: 'none',
    borderRadius: 8,

    textDecoration: 'none',

    transitionTimingFunction: 'ease',
    transitionDuration: '0.3s',
    transitionProperty: 'background-color, color',

    ':hover': {
        backgroundColor: theme.colors.greenHover,
    },
    ':focus': {
        backgroundColor: theme.colors.greenHover,
    },
    ':active': {
        backgroundColor: theme.colors.greenHover,
    },
    '&[disabled]': {
        color: theme.colors.grey,
        backgroundColor: theme.colors.greyLight,
        ':hover': disabledActionStyle,
        ':focus': disabledActionStyle,
        ':active': disabledActionStyle,
    },
});
