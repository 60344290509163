import React from 'react';
import { Link } from 'gatsby';

import getLocalizedPath from '../../i18n/getLocalizedPath';
import { PageContext } from '../../pageContext';
import { LinkType } from '../Link/Link.style';

const LocalizedLink = ({ to, type, forceLocale, ...props }) => (
    <PageContext.Consumer>
        {({ locale }) => {
            const path = getLocalizedPath(to, forceLocale || locale);
            if (type === LinkType.NATIVE) {
                // eslint-disable-next-line
                return <a href={path} {...props} />;
            }
            return <Link {...props} to={path} />;
        }}
    </PageContext.Consumer>
);

export default LocalizedLink;
