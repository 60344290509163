import GreycliffRegular from '../fonts/GreycliffCF-Regular.otf';
import GreycliffMedium from '../fonts/GreycliffCF-Medium.otf';
import GreycliffBold from '../fonts/GreycliffCF-Bold.otf';
import GreycliffDemiBold from '../fonts/GreycliffCF-DemiBold.otf';
import GreycliffHeavy from '../fonts/GreycliffCF-Heavy.otf';

export default [
    {
        name: 'Greycliff',
        files: [GreycliffRegular],
        options: {
            fontStyle: 'normal',
            fontWeight: '400',
        },
    },
    {
        name: 'Greycliff',
        files: [GreycliffMedium],
        options: {
            fontStyle: 'normal',
            fontWeight: '500',
        },
    },
    {
        name: 'Greycliff',
        files: [GreycliffBold],
        options: {
            fontStyle: 'normal',
            fontWeight: '700',
        },
    },
    {
        name: 'Greycliff',
        files: [GreycliffDemiBold],
        options: {
            fontStyle: 'normal',
            fontWeight: '600',
        },
    },
    {
        name: 'Greycliff',
        files: [GreycliffHeavy],
        options: {
            fontStyle: 'normal',
            fontWeight: '900',
        },
    },
];
