export default ({ theme, color, light }) => ({
    padding: '0 1rem',

    wordBreak: 'break-word',

    ...(!light && {
        fontWeight: '700',
    }),

    '& strong': {
        fontWeight: 700,
    },

    color: theme.colors[color] || color,

    fontSize: '2rem', // 40px
    lineHeight: '2.5rem', // 64px

    min480px: {
        fontSize: '2.5rem', // 40px
        lineHeight: '3rem', // 64px
    },

    min768px: {
        fontSize: '3.5rem', // 56px
        lineHeight: '4.375rem', // 70px
    },
});
