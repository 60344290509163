import React, { useState } from 'react';
import PropTypes from 'prop-types';
import { FelaRenderer, ThemeProvider } from 'react-fela';

import { IntlProvider } from 'react-intl';

import PageContext from './PageContext';

import reset from '!css-loader!../styles/reset.css';
import vendors from '!css-loader!../styles/vendors.css';
import defaults from '!css-loader!../styles/default.css';

import theme from '../styles/theme';
import fonts from '../styles/fonts';

import { translations } from '../i18n';

if (!Intl.PluralRules) {
    require('@formatjs/intl-pluralrules/polyfill');
    require('@formatjs/intl-pluralrules/dist/locale-data/cs');
    require('@formatjs/intl-pluralrules/dist/locale-data/en');
}
if (!Intl.RelativeTimeFormat) {
    require('@formatjs/intl-relativetimeformat/polyfill');
    require('@formatjs/intl-relativetimeformat/dist/locale-data/cs');
    require('@formatjs/intl-relativetimeformat/dist/locale-data/en');
}

const withPageContext = PageComponent => props => {
    const [locale, setLocale] = useState(props.pageContext.locale);
    return (
        <ThemeProvider theme={theme}>
            <FelaRenderer>
                {renderer => {
                    fonts.forEach(font => {
                        renderer.renderFont(font.name, font.files, font.options);
                    });

                    renderer.renderStatic(reset.toString());
                    renderer.renderStatic(vendors.toString());
                    renderer.renderStatic(defaults.toString());
                    return (
                        <IntlProvider locale={locale} messages={translations[locale]} textComponent={React.Fragment}>
                            <PageContext.Provider value={{ ...props.pageContext, locale, setLocale }}>
                                <PageComponent {...props} locale={locale} setLocale={setLocale} />
                            </PageContext.Provider>
                        </IntlProvider>
                    );
                }}
            </FelaRenderer>
        </ThemeProvider>
    );
};

withPageContext.propTypes = {
    children: PropTypes.node.isRequired,
};

export default withPageContext;
