import React, { useCallback } from 'react';
import PropTypes from 'prop-types';

import { Helmet } from 'react-helmet';
import { injectIntl } from 'react-intl';
import { PageContext } from '../../pageContext';
import getLocalizedPath from '../../i18n/getLocalizedPath';
import { graphql, useStaticQuery } from 'gatsby';

import socialImage from '../../images/social.jpg';
import { languages } from '../../i18n';

const SEO = ({ title, desc, intl, children = null }) => {
    const { site } = useStaticQuery(
        graphql`
            query {
                site {
                    siteMetadata {
                        url
                    }
                }
            }
        `,
    );

    const generateMetaLinks = useCallback(
        (languages, originalPath) =>
            languages.map(language => {
                const url = `${site.siteMetadata.url}${getLocalizedPath(originalPath, language.locale)}`;
                const res = [<link rel="alternate" hrefLang={language.locale} href={url} />];
                if (language.default) {
                    res.push(<link rel="alternate" hrefLang="x-default" href={url} />);
                    res.push(<link rel="canonical" href={url} />);
                }
                return res;
            }),
        [site],
    );

    return (
        <PageContext.Consumer>
            {({ locale, originalPath }) => (
                <Helmet
                    htmlAttributes={{
                        lang: locale,
                    }}
                >
                    <title>{intl.formatMessage({ id: title })}</title>
                    <meta name="description" content={intl.formatMessage({ id: desc })} />

                    {generateMetaLinks(languages, originalPath)}

                    <meta
                        property="og:url"
                        content={`${site.siteMetadata.url}${getLocalizedPath(originalPath, locale)}`}
                    />
                    <meta property="og:type" content="website" />
                    <meta property="og:title" content={intl.formatMessage({ id: title })} />
                    <meta property="og:description" content={intl.formatMessage({ id: desc })} />
                    <meta property="og:image" content={`${site.siteMetadata.url}${socialImage}`} />

                    {children}
                </Helmet>
            )}
        </PageContext.Consumer>
    );
};

SEO.propTypes = {
    title: PropTypes.string.isRequired,
    desc: PropTypes.string.isRequired,
    children: PropTypes.node,
};

export default injectIntl(SEO);
